import { faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box, { boxStyles } from "../../../components/box/Box";
import Button from "../../../components/button/Button";
import DataMap from "../../../components/data/DataMap";
import Divider from "../../../components/divider/Divider";
import { addLog } from "../../../components/feedback/Feedback";
import useForm from "../../../components/form/Form";
import CheckInput from "../../../components/input/CheckInput";
import TextInput from "../../../components/input/TextInput";
import WSPayEntryForm from "../../../components/paymentGateway/wsPay/layouts/WSPayEntryForm";
import ResponsiveSection from "../../../components/responsiveSection/ResponsiveSection";
import { ResponseStatus, usePostData } from "../../../hooks/api/Api";
import getLocales from "../../../locale/Locale";
import Style from "../../../styles/Style";
import { WSPayPaymentTypeList } from "../../../utils/Entities";
import { isEmail, isNullOrEmpty } from "../../../utils/TypeUtil";
import { submitOrderApi } from "../../../webShop/api/SubmitOrder";
import useCartInfo, { CART_ID_STORAGE_KEY } from "../../../webShop/context/CartInfo";
import { termsAndPrivacyInfoPage } from "../../legal/TermsAndPrivacyInfoPage";
import './OrderCheckout.scss';

enum Structure {
    accentBox = 'accent_box',
    creditCardBox = 'credit_card_box',
    legalLink = 'legal_link'
}

export default function OrderCheckout(){

    const { lang } = getLocales()
    const { cart } = useCartInfo()

    const [ showWsPay, setShowWsPay ] = useState(false)
    useEffect(() => {
        if(cart && cart.needsToBeProcessedByPaymentGateway){
            setShowWsPay(true)
        }
    }, [cart])

    const save = usePostData({
        api:submitOrderApi,
        onResponse:(response) => {
            if(response){
                addLog(response)
            }
            if(response?.status == ResponseStatus.SUCCESS){
                form.reload()
                setShowWsPay(true)
            }
        }
    })

    const form = useForm({
        data: {  } as Hotelstouch.WebApp.WebShop.Api.SubmitOrder.Request,
        validator: (data) => {
            let error = {} as Hotelstouch.WebApp.Components.Form.Errors<Hotelstouch.WebApp.WebShop.Api.SubmitOrder.Request>

            if(isNullOrEmpty(data.contactEmail)) error.contactEmail = lang.fieldIsRequired
            else if(!isEmail(data.contactEmail)) error.contactEmail = lang.valueNeedsToBeAValidEmail

            if(isNullOrEmpty(data.firstName)) error.firstName = lang.fieldIsRequired
            if(isNullOrEmpty(data.lastName)) error.lastName = lang.fieldIsRequired
            if(!data.agreeToTermsAndConditions) error.agreeToTermsAndConditions = lang.fieldIsRequired

            return error
        },
        onSubmit: (data) => {
            const cartId = localStorage.getItem(CART_ID_STORAGE_KEY) || undefined
            save.execute({...data, cartId })
        },
        dependencyArray: [cart]
    })

    return <>
        {showWsPay && <WSPayEntryForm />}
        <Divider />

        <ResponsiveSection class={Structure.accentBox} style={{ backgroundColor: 'transparent', paddingTop: 0, paddingBottom: 0 }}>
            <h2>{lang.payMethod}:</h2>
            <span style={{ fontSize: '0.9rem' }}>{lang.creditCardIsTheOnlyPayMethod}</span>
            <br/>
            <br/>
            <div className={Structure.creditCardBox}>
                <div><span style={{ color: Style.variables.colorNeutral, marginRight: '1rem' }}><FontAwesomeIcon icon={faCircleDot} /></span> {lang.creditCard}:</div>
                <DataMap data={WSPayPaymentTypeList} render={({ data: item, index }) => (
                    <a key={index}  href={item.targetUrl}>
                        <img className={'Structure.paymentTypeImage'} src={item.imageUrl} alt={item.name} />
                    </a>
                )} />
            </div>
        </ResponsiveSection>

        <form style={{ width: '100%' }} onSubmit={form.submit} autoComplete={'on'}>

            <ResponsiveSection class={Structure.accentBox}>
                <h2>{lang.completePurchase}</h2>
                <br />

                    <Box class={boxStyles.flexGrid(2)} style={{ columnGap: '4rem' }}>
                        <TextInput 
                            name="firstName" 
                            label={lang.firstName}
                            autocomplete={true}
                            error={form.formVisited ? form.errors.firstName : undefined}
                            value={form.data.firstName}
                            onChange={form.setValue} 
                        />
                        

                        <TextInput 
                            name="lastName" 
                            label={lang.lastName}
                            autocomplete={true}
                            error={form.formVisited ? form.errors.lastName : undefined}
                            value={form.data.lastName}
                            onChange={form.setValue} 
                        />

                        <TextInput 
                            name="contactEmail" 
                            label={lang.email}
                            autocomplete={true}
                            error={form.formVisited ? form.errors.contactEmail : undefined}
                            value={form.data.contactEmail}
                            onChange={form.setValue} 
                        />
                    </Box>

                    <br /><br />

                    <CheckInput
                        name="agreeToTermsAndConditions"
                        checked={form.data.agreeToTermsAndConditions}
                        label={<span style={{ fontSize: '0.9rem' }}>
                            {lang.agreeToTermsAndConditionsFirstPart}
                            &nbsp;<Link to={termsAndPrivacyInfoPage} className={Structure.legalLink}>{lang.termsOfUseInstrumental}</Link>&nbsp;
                            {lang.agreeToTermsAndConditionsSecondPart}
                        </span>}
                        onChange={form.setValue}
                    />

                    <br /><br />

                    <Box class={boxStyles.flexBox} style={{ flexDirection: 'column', gap: '2rem' }}>
                        <i style={{ opacity: 0.6 }}>{lang.ifYouEnteredAllValuesCorrectlyTheNextStepIsOnlinePayment}</i>

                        <Button
                            render={lang.continue}
                            disabled={!form.isValid() || !cart || !cart.items || !cart.items.length}
                            primary={true}
                            type='submit'
                            style={{ margin: 'auto', background: 'rgb(0 0 0 / 60%)' }}
                            isLoading={save.isLoading}
                        />
                    </Box>

            </ResponsiveSection>

            

        </form>

        <Divider />
    </>
}