import { Link } from "react-router-dom"
import Box, { boxStyles } from "../../components/box/Box"
import DataMap from "../../components/data/DataMap"
import getLocales from "../../locale/Locale"
import { WSPayPaymentTypeList } from "../../utils/Entities"
import { landingPagePath } from "../landingPage/LandingPage"

export const LegalText = () => {
    const { currentLocale } = getLocales()

    const creditCardList = <Box class={boxStyles.flexBox} style={{ gap: '1rem', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        <DataMap data={WSPayPaymentTypeList} render={({ data: item, index }) => (
            <a key={index}  href={item.targetUrl}>
                <img className={'Structure.paymentTypeImage'} src={item.imageUrl} alt={item.name} />
            </a>
        )} />
    </Box>

    if(currentLocale === 'hr'){
        return (<>
            <h2>Načini i uvjeti plaćanja</h2>
            <ul>
                <li>Kreditna kartica će se teretiti za cjelokupni iznos prema uvjetima prihvaćene ponude.</li>
                <li>
                    Primamo sljedeće kartice: Diners, MasterCard, Maestro i Visa<br />
                    {creditCardList}
                </li>
                <li>Kreditna kartica će biti terećena u valuti prikazanoj na ponudi/predračunu te izdanom računu. </li>
                <li>Vaši osobni podaci, kao i podaci o vašoj kreditnoj kartici, u potpunosti su zaštićeni. Prijenos podataka odvija se preko sigurnosnog servera.</li>
                <li>
                    Povrat (Refund) iznosa na kreditnu karticu se izvršava pod sljedećim uvjetima:
                    <ul>
                        <li>Povrat se može zatražiti u roku od 7 dana od izvršene transakcije</li>
                        <li>Povrat će biti proveden na isti način kao što je transakcija izvršena.</li>
                    </ul>
                </li>
                <li>U slučaju da nije moguće teretiti kreditnu karticu, zadržavamo pravo otkaza narudžbe </li>
                <li>Ukoliko imate bilo kakvih pitanja, slobodno nas kontaktirajte na: info@moreska.hr. </li>
            </ul>
            
            <h2>PRODAJNO MJESTO - MOREŠKA</h2>
            <p>
                Sjedište: Foša 2, 20260 Korčula	<br/>
                OIB: 18704976903 <br />
                E-mail: info@moreska.hr
            </p>
    
            <h2>Izjava o zaštiti i prikupljanju osobnih podataka i njihovom korištenju</h2>
            <p>
                Moreška - Korčula prikuplja samo nužne, osnovne osobne podatke kupaca koji su neophodni za ispunjenje poslovnih obveza. Prikupljeni osobni podaci strogo se čuvaju, koriste se samo u svrhe za koje su prikupljeni te je pristup istima omogućen samo ovlaštenim zaposlenicima. 
            </p>
            <p>
                Svi djelatnici Moreške - Korčula i poslovni partneri odgovorni su za poštivanje načela zaštite privatnosti.
            </p>
    
            <h2>
                Izjava o korištenju WSPay-a
            </h2>
    
            <p>
                Moreška - Korčula  koristi WSPay sustav za online plaćanja.
            </p>
            <a href="http://www.wspay.info" title="WSpay - Web Secure Payment Gateway" target="_blank"><img alt="WSpay - Web Secure Payment Gateway" src="https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png" /></a>
            <p>
                WSPay je siguran sustav za online plaćanje, plaćanje u realnom vremenu, kreditnim i debitnim karticama te drugim načinima plaćanja. WSPay kupcu i trgovcu osigurava siguran upis i prijenos upisanih podataka o karticama što podvrđuje i PCI DSS certifikat koji WSPay ima. WSPay koristi SSL certifikat 256 bitne enkripcije te TLS 1.2 kriptografski protokol kao najviše stupnjeve zaštite kod upisa i prijenosa podataka.
            </p>
    
            <h2>Izjava o zaštiti prijenosa osobnih podataka </h2>
            
            <p>
                Zaštita osobnih podataka u skladu sa Općom uredbom o zaštiti podataka Europskog parlamenta i Vijeća broj 2016/679-Uredba te provedbe Opće uredbe o zaštiti podataka 
            </p>
            <p>
                WSPay kao izvršitelj provođenja autorizacije i naplate kreditnih kartica postupa s osobnim podacima u svojstvu izvršitelja obrade te sa osobnim podacima postupa u skladu sa Općom uredbom o zaštiti podataka Europskog parlamenta i Vijeća broj 2016/679 te prema strogim pravilima PCI DSS L1 regulative o zaštititi upisa i prijenosa podataka.
            </p>
            <p>
                WSPay koristi SSL certifikat 256 bitne enkripcije te TLS 1.2 kriptografski protokol kao najviše stupnjeve zaštite kod upisa i prijenosa podataka.
            </p>
            <p>
                Osobni podaci koji se koriste u svrhu autorizacije i naplate odnosno u izvršavanja obveza iz Ugovora ili temeljem Ugovora, smatraju se povjerljivim podacima.
            </p>
            <p>
                WSPay osobne podatke ne obrađuje niti koristi osim u svrhe izvršenja ugovora autorizacije i naplate.
            </p>
            <p>
                WSPay jamči ispunjavanje svih uvjeta određenih važećim propisima o zaštiti osobnih podataka za izvršitelje obrade osobnih podataka, a osobito poduzimanje svih potrebnih tehničkih, organizacijskih i sigurnosnih mjera a to naročito potvrđuje i PCI DSS L1 certifikatom.
            </p>
        </>)
    }
    else {
        return <>
            <h2>Payment terms and conditions</h2>
                <ul>
                    <li>Credit card will be charged for the total amount according to the terms in accepted proposal. </li>
                    <li>We accept following credit cards: MasterCard, Maestro and Visa </li>
                    {creditCardList}
                    <li>Credit card will be charged in currency shown on the Proposal/Proforma Invoice and issued Invoice.</li>
                    <li>Your personal information, as well as your credit card details, are fully protected. Data transfer is taking place on a secured server. </li>
                    <li>
                        Refunds are granted per conditions below:
                        <ul>
                            <li>Refund can be requested within 7 days of purchase</li>
                            <li>Refunds will be done at the same way the payment is processed.  </li>
                        </ul>
                    </li>
                    <li>In case that credit card could not be charged, we reserve the right to cancel your order </li>
                    <li>If you have any questions, feel free to contact us at info@moreska.hr</li>
                </ul>
                
                <h2>MERCHANT - MORESKA</h2>
                <p>
                    Head office: Fosa 2, 20260 Korcula, Croatia<br />
                    VAT: HR18704976903<br />
                    E-mail: info@moreska.hr<br />
                </p>
                <p>
                    More details about Moreska - Korcula are available at <Link to={landingPagePath}>https://www.moreska.hr</Link>
                </p>

                <h2>Statement on Protection and Collection of Personal Data and their Use</h2>
                <p>
                    Moreska - Korcula provides personal data protection by collecting only basic customer information necessary to meet business obligations. Collected customer personal data are safely stored and used only for the purposes for which they were collected.
                </p>
                <p>Access to the collected personal data is restricted to the authorised employees only.</p>
                <p>All Moreska - Korcula employees and business partners are responsible for respecting the principles of privacy protection. </p>

                <h2>Statement of WSPay usage</h2>
                <p>Moreska - Korcula uses WSPay for online payments. </p>
                <a href="http://www.wspay.info" title="WSpay - Web Secure Payment Gateway" target="_blank"><img alt="WSpay - Web Secure Payment Gateway" src="https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png" /></a>
                <p>WSPay is a secure online payment system, real-time payments, credit and debit cards, and other payment methods. The WSPay customer and the merchant ensure secure enrollment and transfer of the tab data entered, as well as the PCI DSS certificate that WSPay has. WSPay uses 256-bit encryption SSL certificate and TLS 1.2 cryptographic protocol as the highest degree of data protection and data security.</p>

                <h2>Statement on the Protection of Personal Data Transfer</h2>
                <p>
                    Protection of personal data in accordance with the General Regulation on data protection of the European Parliament and Council No. 2016/679-Regulation and implementation of the General Data Protection Regulation.
                </p>
                <p>
                    WSPay as a credit card authorization and credit card performer processes personal data as a processing agent and processes personal data in accordance with the General Regulation on the Protection of Data of the European Parliament and Council No. 2016/679 and the strict rules of the PCI DSS L1 Regulations on Protection of Registrations and data transfer.
                </p>
                <p>WSPay uses 256-bit encryption SSL certificate and TLS 1.2 cryptographic protocol as the highest degree of data protection and data security.</p>
                <p>Personal data used for authorization and collection purposes, or for performance of the contract or contract obligations, are considered confidential.</p>
                <p>WSPay does not process personal data except for the purpose of executing authorization and billing.</p>
                <p>
                    WSPay warrants compliance with all the terms and conditions laid down in the applicable personal data protection regulations for personal data processing executives, and in particular the taking of all necessary technical, organizational and security measures, in particular with the PCI DSS L1 Certified.
                </p>
        </>
    }
    
}