import en from "date-fns/locale/en-GB";
import hr from "date-fns/locale/hr";
import _ from 'lodash';
import moment from "moment";
import 'moment/locale/hr';
import { useEffect } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Box, { boxStyles } from "../../../components/box/Box";
import NoDataBox from "../../../components/box/NoDataBox";
import Button from "../../../components/button/Button";
import Card from "../../../components/card/Card";
import DataMap from "../../../components/data/DataMap";
import Icon from "../../../components/icon/Icon";
import IconList from "../../../components/icon/IconList";
import ResponsiveSection from "../../../components/responsiveSection/ResponsiveSection";
import getLocales, { getCurrentLocale } from "../../../locale/Locale";
import Style from "../../../styles/Style";
import { formatCurrency } from "../../../utils/TypeUtil";
import useCartInfo from "../../../webShop/context/CartInfo";
import './ProductList.scss';

enum Structure {
    accentBox = 'accent_box',
    productCard = 'product_card',
    productPage = 'product_page',
    filmingText = 'filming_text',
    cardHorizontal = 'card_horizontal',
    dateTitle = 'date_title',
    dateSubtitle = 'date_subtitle',
    locationInfo = 'location_info'
}

export default function ProductList(){

    const { lang } = getLocales()
    const { updateOrderItem, productList: list, isLoading } = useCartInfo()

    useEffect(() => {
        if(getCurrentLocale() === 'hr'){
            registerLocale("hr", hr)
        }
        else {
            registerLocale("en", en)
        }
    }, [])

    const currentList = _.sortBy(list && list.length > 0 ? list.filter(x => x.productDate && moment(x.productDate, "YYYY-MM-DD HH:mm") > moment()) : [], 'productDate')
    return <div className={Structure.productPage}>
        <ResponsiveSection class={Structure.accentBox} style={{ backgroundColor: 'transparent', paddingTop: 0, paddingBottom: 0 }}>
            <h3>{lang.ticketPrices}:</h3>
            <p style={{ whiteSpace: 'pre-line' }}>
                {lang.ticketPricesText}
            </p>
            <span className={Structure.filmingText}>{lang.filmingPermissionText}</span><br />
        </ResponsiveSection>

        <ResponsiveSection class={Structure.accentBox}>
            <h2 style={{ marginTop: 0 }}>{lang.tickets}:</h2>

            {currentList && currentList.length > 0 ?
                <Box class={boxStyles.flexBox} style={{ gap: '2rem', flexDirection: 'column' }}>
                    <DataMap data={currentList} render={({ data: item }) => {
                        const momentDate = moment(item.productDate, "YYYY-MM-DD HH:mm").locale(getCurrentLocale());
                        return <Card 
                            key={item.id}
                            info={<>
                                <div>
                                    {item.locationName ? <div className={Structure.locationInfo}><Icon icon={IconList.location} /> {item.locationName}</div> : null}
                                    <div className={Structure.dateTitle}>{momentDate.format("DD")} <span>{momentDate.format("MMM")}</span></div>
                                    <div className={Structure.dateSubtitle}>{momentDate.format("ddd HH:mm")}</div>
                                </div>
                            </>}
                            title={item.title} 
                            style={{ borderRadius: '5px', boxShadow: 'none' }}
                            class={`${Structure.productCard} ${Structure.cardHorizontal}`}
                            subtitle={<>{lang.price}: <b>{formatCurrency(item.price)} {item.currency}</b></>}
                            actions={<>
                                <Button
                                    style={{ margin: 'auto', backgroundColor: '#0000003b', color: Style.variables.colorTextAlt }}
                                    render={lang.addToOrder}
                                    disabled={item.isOutOfStock}
                                    primary={true}
                                    onClick={() =>  updateOrderItem({ product: item, quantity: 1 }) }
                                    isLoading={isLoading}
                                />
                            </>}
                        />
                    }}
                    />
                </Box>
                :
                (isLoading ? null : <NoDataBox text={lang.noAvailableTicketsForSelectedDate} />)
            }
            <br/>
        </ResponsiveSection>

    </div>
}