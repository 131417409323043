import React, { useContext, useEffect, useState } from "react"
import { addLog } from "../../components/feedback/Feedback"
import { ResponseStatus, useGetData } from "../../hooks/api/Api"
import { isNullOrEmpty } from "../../utils/TypeUtil"
import { getCartInfo } from "../api/CartInfo"
import { getProductList } from "../api/ProductList"
import { updateOrderItemApi } from "../api/UpdateOrderItem"

type StoreType = Hotelstouch.WebApp.Context.CartInfo.Model
let CartInfoContext:React.Context<StoreType>

export const CART_ID_STORAGE_KEY = "CART_ID"

export default function useCartInfo(){
    return useContext(CartInfoContext as React.Context<StoreType>)
}
export function CartInfoProvider(props: { children: React.ReactNode }){
    const hook = useCartInfoHook()
    if(!CartInfoContext) CartInfoContext = React.createContext(hook)

    return <CartInfoContext.Provider
            value={hook}
            children={props.children}
        />
}
export function useCartInfoHook(): StoreType {
    const [ cartData, setCartData ] = useState<Hotelstouch.WebApp.WebShop.Api.CartInfo.Cart>()
    const { list, reload: reloadProducts, isLoading: productsLoading } = useGetData({
        api:getProductList
    })

    useEffect(() => {
        load()
    }, [])

    async function load():Promise<void>{
        const cartId = localStorage.getItem(CART_ID_STORAGE_KEY) || undefined
        const response = await getCartInfo({ cartId })
        if((response && !response.clearSession) || !response.object){
            setCartData(response.object)
        }
        if((!response || !response.object) && cartId){
            localStorage.removeItem(CART_ID_STORAGE_KEY)
        }
        if(response && response.clearSession){
            localStorage.removeItem(CART_ID_STORAGE_KEY)
        }

        reloadProducts()
    }

    const removeOrderItem = async(id: number, productId: number) => {
        let orderItemList = cartData ? cartData.items : []

        if(!cartData || !cartData.items){
            return
        }

        const cartId = localStorage.getItem(CART_ID_STORAGE_KEY) || undefined
        let res = await updateOrderItemApi({ id, quantity: 0, productId, cartId })
        addLog(res)

        if(!isNullOrEmpty(res.cartId)){
            localStorage.setItem(CART_ID_STORAGE_KEY, res.cartId)
        }
        if(res.status === ResponseStatus.SUCCESS){
            orderItemList = cartData.items.filter(x => x.id !== id)
            setCartData({ ...cartData, items: orderItemList })

            reloadProducts()
        }
    }

    const updateOrderItem = async ({ id, quantity, product }: Hotelstouch.WebApp.Context.CartInfo.UpdateOrderItemFuncParam) => {
        let orderItemList = cartData ? cartData.items : []

        let cartId = localStorage.getItem(CART_ID_STORAGE_KEY) || undefined

        // If product is already in cart and is not charged separately we need to increase quantitiy
        if(!id && product && product.isQuantityDependant){
            let existingOrderItem = orderItemList.find(x => 
                x.pibpId === product.id
            )
            if(existingOrderItem){
                id = existingOrderItem.id
                quantity = existingOrderItem.quantity + (quantity && quantity > 0 ? quantity : 1)
            }
        }

        if(id && cartData && cartData.items){
            let orderItem = cartData.items.find(x => x.id === id)
            if(!orderItem){
                return
            }

            if(quantity && quantity !== orderItem.quantity){
                let res = await updateOrderItemApi({ id, quantity, productId: product.id, cartId })
                addLog(res)

                if(!isNullOrEmpty(res.cartId)){
                    localStorage.setItem(CART_ID_STORAGE_KEY, res.cartId)
                }
                if(res.status === ResponseStatus.SUCCESS){
                    load()
                }
            }
            else{
                // Remove from cart
                let res = await updateOrderItemApi({ id, quantity: 0, productId: product.id, cartId })
                addLog(res)

                if(!isNullOrEmpty(res.cartId)){
                    localStorage.setItem(CART_ID_STORAGE_KEY, res.cartId)
                }
                if(res.status === ResponseStatus.SUCCESS){
                    orderItemList = cartData.items.filter(x => x.id !== id)
                    setCartData(Object.assign(cartData, { items: orderItemList }))
                }
            }
        }
        else if(product){
            let _quantity = quantity !== undefined ? quantity : 1
            let res = await updateOrderItemApi({ productId: product.id, quantity: _quantity, cartId })
            addLog(res)

            if(!isNullOrEmpty(res.cartId)){
                localStorage.setItem(CART_ID_STORAGE_KEY, res.cartId)
            }
            if(res.status === ResponseStatus.SUCCESS){
                load()
            }
        }
    }

    return {
        cart: cartData,
        reload: load,
        updateOrderItem,
        removeOrderItem,
        productList: list,
        isLoading: productsLoading
    }
}