import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import moment from "moment";
import getLocales from "../../locale/Locale";

type Inport = {
    expirationDateTime: Moment
    onTimeout?:() => void
}
export default function Countdown({ expirationDateTime, onTimeout }: Inport){
    const [timeRemaining, setTimeRemaining] = useState(expirationDateTime.diff(moment(), 's'));
    const { lang } = getLocales()

    useEffect(() => {
        const timer = setInterval(function() {
            if (timeRemaining > 0) {
                setTimeRemaining(timeRemaining - 1)
            }
            else{
                if(onTimeout){
                    onTimeout();
                }
                clearInterval(timer)
            }
        }, 1000)
    
        return () => {
           clearInterval(timer)
        }
    }, [timeRemaining]);

    return <>
        {timeRemaining > 0 ?
            <div>
                {moment("1950-01-01").startOf('day').seconds(timeRemaining).format('mm:ss')}
            </div>
            : 
            <div>
                {lang.timeIsUp}
            </div>
        }
    </>
}