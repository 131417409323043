import React from "react";
import RenderProps from "../../helpers/RenderProps";
import Style from "../../styles/Style";
import Icon from "../icon/Icon";
import IconList from "../icon/IconList";
import './Button.scss'

enum Structure {
    button = 'button',
    notification = 'button_notification'
}
export default function Button<T>(props: Hotelstouch.WebApp.Components.Button.Import){
    const buttonMain = 
        <button 
            className={`${Structure.button} ${props.class ? props.class : ''} ${!props.notClickable ? Style.effects.cursor.pointer : ''}`}
            type={props.type || 'button'}
            style={props.style}
            data-disabled={props.disabled || props.isLoading || undefined}
            data-primary={props.primary || undefined}
            onClick={() => props.onClick && !props.notClickable && props.onClick()}
            >
                {props.isLoading && <Icon clazz={Style.animations.spin} icon={IconList.spinner} />}{props.isLoading && props.render && <>&nbsp;&nbsp;</>}
                {!props.isLoading && props.media && RenderProps(props, 'media')}{props.media && props.render && <>&nbsp;&nbsp;</>}
                {!props.isLoading && props.notification && <div className={Structure.notification}>{RenderProps(props, 'notification')}</div>}
                {props.render && RenderProps(props, 'render')}
        </button>
        if(props.link){
            return <a href={props.link} target={props.targetBlank ? '_blank' : undefined}>
                {buttonMain}
            </a>
        }
        else{
            return buttonMain
        }
}

export const buttonStyles = {
    round: 'btn_round'
}