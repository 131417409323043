import React, { useState } from "react"
import Style from "../../styles/Style"
import DataMap from "../data/DataMap"
import './GridGallery.scss'

enum Structure {
    gallery = 'grid_gallery',
    item = 'gallery_item'
}
export default function GridGallery(props: Hotelstouch.WebApp.Components.Gallery.Import){
    return <><div 
        className={`${Structure.gallery}`}
        style={props.style}
        children={
            <DataMap data={props.images} render={({ data: image, index }) => {
                return <div 
                    key={index}
                    className={`${Structure.item}`}
                    style={{ backgroundImage: `url('${image.url}')` }}
                />
            }} />
        } />
        </>
}