const clientPort = `:${document.location.port}`
const serverPort = process.env.NODE_ENV === 'development' ? `:${9099}` : `:${document.location.port}`
export const clientUrl:string = `${document.location.protocol}//${document.location.hostname}${clientPort}`

let _serverUrl = ""
if(document.location.hostname.indexOf("dev") > 0){
    _serverUrl = "pos.dev.hotelstouch.com:9099/pos"
}
else if(document.location.hostname.indexOf("test") > 0){
    _serverUrl = "pos.test.hotelstouch.com"
}
else {
    _serverUrl = "pos.hotelstouch.com"
}

export const serverUrl = document.location.protocol + "//" +_serverUrl
export const applicationCode = "owie214jifofIOJfwf3"