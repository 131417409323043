import React from "react";
import getLocales from "../../locale/Locale";
import Style from "../../styles/Style";
import Box, { boxStyles } from "./Box";
import './Box.scss';

export default function NoDataBox(props: { text?: string }){
    const { lang } = getLocales()

    return <Box class={boxStyles.flexBox} style={{ flexDirection: 'column', width: '100%', height: '100%', padding: '1.5rem' }}>
        <img src="/images/moreska-logo-štit.svg" style={{ maxWidth: '100px', width: '100%' }} />
        <br />
        <div style={{ fontSize: '1.5rem', opacity: 0.6 }}>{props.text || lang.noData}</div>
    </Box>
}