import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import getLocales from "../../locale/Locale";
import { aboutUsPagePath } from '../../pages/aboutUs/AboutUs';
import { contactUsPagePath } from '../../pages/contactUs/ContactUs';
import { landingPagePath } from '../../pages/landingPage/LandingPage';
import { termsAndPrivacyInfoPage } from "../../pages/legal/TermsAndPrivacyInfoPage";
import { webShopPath } from '../../pages/webshop/WebShop';
import { WSPayPaymentTypeList } from "../../utils/Entities";
import DataMap from '../data/DataMap';
import ResponsiveSection from "../responsiveSection/ResponsiveSection";
import './Footer.scss';

enum Structure {
    footer = 'footer',
    footerContent = 'footer_content',
    footerBottomContent = 'footer_bottom_content',
    footerBottomContentDetailsSection = 'footer_bottom_content_details_section',
    paymentTypeLink = 'payment_type_link',
    paymentTypeImage = 'payment_type_image',
    paymentTypeWrapper = 'payment_type_wrapper',
    sectionsWrapper = 'section_wrapper',
    contactWrapper = 'contact_wrapper',
    logoWrapper = 'logo_wrapper',
    socialMediaWrapper = 'social_media_wrapper'
}
export default function Footer(){
    const { lang } = getLocales();

    return <>
        <div className={Structure.footer}>
            <ResponsiveSection>
                <div className={Structure.footerContent}>
                    <div className={Structure.logoWrapper}><img src="/images/moreska_footer.svg" alt="Moreška logo" /></div>
                    <div className={Structure.contactWrapper}>
                        Moreška - kulturno umjetničko društvo - Korčula<br/>
                        {lang.abbreviation}: Moreška - Korčula<br/>
                        {lang.hq}: Foša 2, 20260 Korčula, Croatia<br/>
                        OIB: 18704976903<br/>
                        {lang.registerNumber}: 19000176 (Registar udruga)<br/>
                        PDV ID: HR18704976903<br/>
                        IBAN: HR5824070001100304638
                    </div>
                    <div className={Structure.sectionsWrapper}>
                        <Link to={landingPagePath}>{lang.homePage}</Link>
                        <Link to={aboutUsPagePath}>{lang.aboutUs}</Link>
                        <Link to={webShopPath}>{lang.buyTickect}</Link>
                        <Link to={contactUsPagePath}>{lang.contact}</Link>
                        <div className={Structure.socialMediaWrapper}>
                            <a href='https://www.facebook.com/Moreska.Korcula'><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a href='https://www.instagram.com/moreska.korcula/?hl=en'><FontAwesomeIcon icon={faInstagram} /></a>
                        </div>
                    </div>
                    <div className={Structure.paymentTypeWrapper}>
                        <DataMap data={WSPayPaymentTypeList} render={({ data: paymentType, index }) => {
                            return <a key={index} className={Structure.paymentTypeLink} href={paymentType.targetUrl}>
                                <img className={Structure.paymentTypeImage} src={paymentType.imageUrl} alt={paymentType.name} />
                            </a>
                        }} />
                    </div>
                </div>
                <div className={Structure.footerBottomContent}>
                    <div>©2022 Moreška - Korčula. Sva prava pridržana.</div>
                    <div className={Structure.footerBottomContentDetailsSection}>
                        <Link to={aboutUsPagePath} children={"Impressum"} />
                        <Link to={termsAndPrivacyInfoPage} children={lang.termsPaymentAndDataProtection} />
                        <span>Designed and developed by: STATIM d.o.o.</span>
                    </div>
                </div>  
            </ResponsiveSection>
        </div>
    </>
}