import { Route, Routes } from "react-router"
import AboutUs, { aboutUsPagePath } from "../pages/aboutUs/AboutUs"
import ContactUs, { contactUsPagePath } from "../pages/contactUs/ContactUs"
import LandingPage, { landingPagePath } from "../pages/landingPage/LandingPage"
import TermsAndPrivacyInfoPage, { termsAndPrivacyInfoPage } from "../pages/legal/TermsAndPrivacyInfoPage"
import OrderInfoPage, { orderInfoPagePath, orderInfoPagePathNoParam } from "../pages/webshop/OrderInfoPage"
import WebShop, { webShopPath } from "../pages/webshop/WebShop"

export default function AppRoutes(){
    return <Routes>
        <Route path={landingPagePath} element={<LandingPage />}/>
        <Route path={aboutUsPagePath} element={<AboutUs />}/>
        <Route path={contactUsPagePath} element={<ContactUs />}/>
        <Route path={webShopPath} element={<WebShop />}/>
        <Route path={orderInfoPagePathNoParam} element={<OrderInfoPage />}/>
        <Route path={orderInfoPagePath} element={<OrderInfoPage />}/>
        <Route path={termsAndPrivacyInfoPage} element={<TermsAndPrivacyInfoPage />}/>
    </Routes>
}