import React from "react";
import Page from "../../components/page/Page";
import ResponsiveSection from "../../components/responsiveSection/ResponsiveSection";
import Seo from "../../components/seo/Seo";
import getLocales from "../../locale/Locale";
import ContactUsForm from "./components/ContactUsForm";
import './ContactUs.scss'

enum Structure{
    sectionTitle = 'about_us_section_title',
    containerPadded = 'container_padded'
}

export const contactUsPagePath = "/kontakt";
export default function ContactUs(){
    const { lang } = getLocales()

    return <>
        <Seo title={lang.contact} />
        <Page 
            headerUrl={'/images/impressum.webp'}
            title={lang.contact}
        >
            <ResponsiveSection class={Structure.containerPadded}
                children={<>
                    <ContactUsForm />
                </>}
            />
        </Page>
    </>
}