import React from 'react';
import Footer from '../footer/Footer';
import './Page.scss';

export enum Structure {
    page = 'page',
    pageContent = 'page_content',
    pageHeader = 'page_header',
    pageHeaderWrapper = 'page_header_wrapper',
    pageHeaderDivider = 'page_header_divider',
    pageHeaderTitle = 'page_header_title',
    pageHeaderSubtitle = 'page_header_subtitle'
}

export default function Page(props: Hotelstouch.WebApp.Components.Page.Import){
    return <>
        <div className={Structure.page}>
            <div className={Structure.pageHeader} style={{backgroundImage: `url(${props.headerUrl})` }}>
                <div className={Structure.pageHeaderWrapper}>
                    {props.title && <div className={Structure.pageHeaderTitle}>{props.title}</div>}
                    {props.subtitle && <>
                        <div className={Structure.pageHeaderDivider}></div>
                        <div className={Structure.pageHeaderSubtitle}>{props.subtitle}</div>
                    </>}
                </div>
            </div>
            <div className={Structure.pageContent}>{props.children}</div>
            <Footer />
        </div>
    </>
}