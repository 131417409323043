import React, { useEffect } from 'react'
import { useGetData } from '../../../../hooks/api/Api'
import getLocales from '../../../../locale/Locale'
import { CART_ID_STORAGE_KEY } from '../../../../webShop/context/CartInfo'
import Box from '../../../box/Box'
import { getPaymentGatewayData } from '../apis/PaymentGatewayData'

export default function WSPayEntryForm(){
    const info = useGetData({
        api: getPaymentGatewayData,
        params: { cartId: localStorage.getItem(CART_ID_STORAGE_KEY) || undefined }
    })
    const { lang } = getLocales()

    const formRef = React.createRef<HTMLFormElement>()

    useEffect(() => {
        if(info.object && formRef && formRef.current){
            formRef.current.submit()
        }
    }, [formRef, info.object])

    if(info.isLoading){
        return <Box>
            {lang.loading}...
        </Box>
    }
    else if(!info.isLoading && !info.object){
        return <Box>
            {lang.anErrorOccurredWhenGettingPaymentData}
        </Box>
    }
    else if(!info.object){
        return null
    }
    else{
        return<>
            <div>
                <form name="pay" action={info.object._serviceUrl} method="POST" ref={formRef}>
                    {
                        info.object.keyValueParameters.map(keyVal => {
                            return <input type="text" key={keyVal.key} name={keyVal.key} value={keyVal.value} />
                        })
                    }
                    <input type="submit" value={lang.goToPaymentGateway} />
                </form>
            </div>
        </>
    }
    
}
