import React from "react";
import RenderProps from "../../helpers/RenderProps";
import './Input.scss'

enum Structure {
    area = 'input_area',
    media = 'input_media'
}
export default function TextInput(props: Hotelstouch.WebApp.Components.Input.Text.Import){
    return <div className={Structure.area} data-label={props.label} data-error={props.error}>
        <input
            {...props.htmlElementProps}
            type={props.type || (props.name === 'password' ? 'password' : 'text')}
            autoComplete={props.autocomplete ? 'on' : 'off'}
            required={props.required}
            readOnly={props.readOnly}
            minLength={props.minLength}
            maxLength={props.maxLength}
            pattern={props.pattern}
            value={props.value || ''}
            placeholder={props.placeholder || ' '}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange({ key: props.name, value: event.currentTarget.value })}
        />
        {props.media && <div className={Structure.media}>{RenderProps(props, 'media')}</div>}
    </div>
}