import { FormEvent, useEffect, useState } from "react";
import _ from 'lodash'

export default function useForm<T>(props: Hotelstouch.WebApp.Components.Form.Import<T>){
    const [ state, setState ] = useState<T>(props.data ? props.data : ({} as T))
    const [formVisited, setFormVisited] = useState<boolean>(false)

    useEffect(() => {
        if(props.dependencyArray){
            reload()
        }
    }, props.dependencyArray)

    const reload = () => {
        setState(props.data ? props.data : ({} as T))
        setFormVisited(false)
    }

    const isValid = () => {
        if (props && props.validator) {
            return  _.isEmpty(props.validator(state));
        } 
        else {
            return true
        }
    }

    const submit = (event: FormEvent) => { 
        if(event){
            event.preventDefault()
        }
        if(props.onSubmit && isValid()) {
            props.onSubmit(state) 
        }
    }

    const setValue = (keyValuePair: Hotelstouch.WebApp.Components.Form.KeyValuePair<T>) => {
        setState({ ...state, [keyValuePair.key]: keyValuePair.value })
        setFormVisited(true)
    }

    const setMultiple = (data: Partial<T>) => {
        setState({ ...state, ...data })
        setFormVisited(true)
    }

    const getErrors = () => {
        if(props.validator){
            return props.validator(state)
        }
        else{
            return {} as Hotelstouch.WebApp.Components.Form.Errors<T>
        }
    }

    return {
        data: state,
        submit,
        reload,
        setValue,
        setMultiple,
        isValid,
        formVisited,
        errors: getErrors()
    }
}