import { Link } from "react-router-dom";
import Page from "../../components/page/Page";
import ResponsiveSection from "../../components/responsiveSection/ResponsiveSection";
import Seo from "../../components/seo/Seo";
import getLocales from "../../locale/Locale";
import { landingPagePath } from "../landingPage/LandingPage";
import { LegalText } from "./Util";

export const termsAndPrivacyInfoPage = "/uvjeti-placanje-zastita-podataka";
export default function TermsAndPrivacyInfoPage(){
    const { lang } = getLocales()

    return <>
        <Seo title={lang.privacyPolicy} />
        <Page 
            headerUrl={'/images/impressum.webp'}
            title={lang.termsPaymentAndDataProtection}>
                <ResponsiveSection style={{ lineHeight: '1.75' }}>
                    <LegalText />
                </ResponsiveSection>
        </Page>
    </>
}