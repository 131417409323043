import React from "react";
import Page from "../../components/page/Page";
import Seo from "../../components/seo/Seo";
import getLocales from "../../locale/Locale";
import { getFullUrlFromRelativeUrl } from "../../utils/Http";
import { CartInfoProvider } from "../../webShop/context/CartInfo";
import OrderCheckout from "./components/OrderCheckout";
import OrderSummary from "./components/OrderSummary";
import ProductList from "./components/ProductList";
import './WebShop.scss';

enum Structure {
    layout = 'webshop_layout'
}

const headerImagePath = '/images/footer_background.jpg'

export const webShopPath = "/karte"
export default function WebShop(){
    const { lang } = getLocales()

    return <>
        <Seo
            title={lang.buyTickect}
            metaDescription={lang.buyTicketOnline}
            socialMediaImageUrl={getFullUrlFromRelativeUrl(headerImagePath)}
        />
        <div className={Structure.layout}>
            <CartInfoProvider>
                <Page
                    headerUrl={headerImagePath}>
                        <ProductList />
                        <OrderSummary />
                        <OrderCheckout />
                </Page>
            </CartInfoProvider>
        </div>
    </>
}