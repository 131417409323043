import en from "./en"
import hr from "./hr"

const allTranslations = { hr: hr(), en: en() }
const _defaultLocale:Hotelstouch.WebApp.Locale.Language = 'hr'

export default function getLocales(){
    const currentLocale = getCurrentLocale()
    return {
        lang: allTranslations[currentLocale],
        currentLocale
    }
}

const STORAGE_KEY_LOCALE = 'current_locale'
export function getCurrentLocale(){
    const localeFromStorage = localStorage.getItem(STORAGE_KEY_LOCALE)
    if(!localeFromStorage){
        localStorage.setItem(STORAGE_KEY_LOCALE, _defaultLocale)
        return _defaultLocale
    }
    else{
        return localeFromStorage as Hotelstouch.WebApp.Locale.Language
    }
}
export function setCurrentLocale(newLocale: Hotelstouch.WebApp.Locale.Language){
    localStorage.setItem(STORAGE_KEY_LOCALE, newLocale)
}