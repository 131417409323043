import React from 'react';
import './Divider.scss'

enum Structure{
    divider = 'divider'
}
export default function Divider(){
    return <div className={Structure.divider}>
        <img src="/images/divider.svg" alt="Moreška štit" />
    </div>
}