import React from 'react';
import { Helmet } from "react-helmet";
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Feedback from './components/feedback/Feedback';
import NavBar from './components/navBar/NavBar';
import ScrollToTop from './components/ScrollToTop';
import Seo from './components/seo/Seo';
import AppRoutes from './routes/AppRoutes';
import getNavigationList from './routes/Navigation';
import './styles/baseStyle.scss';
import './styles/Effects.scss';

function App() {
  return <>
    <Seo companyName={ 'Moreška' } />
    <Helmet>
        <link rel="icon" type="image/x-icon" href={"/images/moreska-favicon.svg"} />
    </Helmet>
    <BrowserRouter>
        <ScrollToTop />
        <NavBar 
            routes={getNavigationList()} 
        />
        
        <AppRoutes />
    </BrowserRouter>
    <Feedback />
  </>;
}

export default App;
