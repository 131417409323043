import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { disableBody, enableBody } from '../../helpers/DomHelpers'
import getLocales, { setCurrentLocale } from '../../locale/Locale'
import Style from '../../styles/Style'
import Button from '../button/Button'
import DataMap from '../data/DataMap'
import Icon from '../icon/Icon'
import IconList from '../icon/IconList'
import './NavBar.scss'
import { CART_BUTTON_UI_ID } from '../../pages/webshop/components/OrderSummary'
import Box, { boxStyles } from '../box/Box'

enum Structure {
    pageMenu = 'page_menu',
    menuLogo = 'menu_logo',
    menuLogoImgLG = 'menu_logo_img_lg',
    menuLogoImgSM = 'menu_logo_img_sm',
    menuLinks = 'menu_links',
    menuItem = 'menu_item',
    menuToggle = 'menu_toggle',
    menuOpened = 'menu_opened',
    smallMenuStrip = 'small_menu_strip',
    languageIcon = 'language_icon',
    actionBox = 'nav_actions_box'
}

export default function NavBar(props: Hotelstouch.WebApp.Components.NavBar.Import){
    const [menuVisible, setMenuVisible] = useState(false)
    const { currentLocale } = getLocales()

    const toggleMenu = () => {
        if(menuVisible){
            enableBody()
        }
        else{
            disableBody()
        }
        setMenuVisible(!menuVisible)
    }
    const closeMenu = () => {
        enableBody()
        setMenuVisible(false)
    }
    const toggleLocale = () => {
        if(currentLocale === 'hr'){
            setCurrentLocale('en')
        }
        else{
            setCurrentLocale('hr')
        }

        window.location.reload()
    }

    return <div className={`${Structure.pageMenu} ${menuVisible ? `${Structure.menuOpened} ${Style.animations.slideInFromTop}` : ''}`}>
        <div className={Structure.smallMenuStrip}>
            <div className={Structure.menuLogo}><Link to={'/'}>
                <img src='/images/moreska-header-logo.svg' alt='Moreška' className={Structure.menuLogoImgLG} />
                <img src='/images/moreska-favicon.svg' alt='Moreška' className={Structure.menuLogoImgSM} />
            </Link></div>
            <div className={Structure.menuToggle} onClick={toggleMenu}>
                {menuVisible ? <Icon icon={IconList.close} /> : <Icon icon={IconList.menuToggle} />}
            </div>
        </div>

        <div className={`${Structure.menuLinks}`}>
            <DataMap data={props.routes} render={({ data, index }) => {
                return <div className={Structure.menuItem} key={index}>
                    <NavLink to={data.url} onClick={closeMenu}>
                        {data.title}
                    </NavLink>
                </div>
            }} />
        </div>

        <div className={`${Structure.actionBox}`}>
            <Box class={`${boxStyles.flexBox} ${boxStyles.flexEnd}`} style={{ gap: '1rem' }}>
                <div id={CART_BUTTON_UI_ID}></div>
                <Button
                    class={Structure.languageIcon}
                    media={<img src={`/images/languages/${currentLocale == 'hr' ? 'en' : 'hr'}.svg`} />}
                    onClick={toggleLocale}
                />
            </Box>
        </div>
        
    </div>
}