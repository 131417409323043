const Style = {
    variables: {
        colorPrimary: 'var(--colorPrimary)',
        colorPrimaryLight: 'var(--colorPrimaryLight)',
        colorPrimaryDark: 'var(--colorPrimaryDark)',
        colorSecondary: 'var(--colorSecondary)',
        colorBackground: 'var(--colorBackground)',
        colorText: 'var(--colorText)',
        colorTextAlt: 'var(--colorTextAlt)',
        fontFamilyMain: 'var(--fontFamilyMain)',
        accentFontPrimary: 'var(--accentFontPrimary)',
        accentFontSecondary: 'var(--accentFontSecondary)',
        colorNeutral: 'var(--colorNeutral)'
    },
    effects: {
        shadow: {
            dark: 'shadow_dark',
            light: 'shadow_light',
            primary: 'shadow_primary'
        },
        background: {
            secondary: 'background_secondary'
        },
        cursor: {
            pointer: 'cursor_pointer'
        },
        hoverScale: 'hover_scale',
        hoverShadow: 'hover_shadow'
    },
    animations: {
        zoomIn: 'zoom_in',
        slideOutLeft: 'slide_out_left',
        slideInFromLeft: 'slide_in_from_left',
        slideOutRight: 'slide_out_right',
        slideInFromRight: 'slide_in_from_right',
        slideOutDown: 'slide_out_down',
        slideInFromTop: 'slide_in_from_top',
        slideInFromBottom: 'slide_in_from_bottom',
        spin: 'spin_animation'
    },
    uiState: {
        hidden: 'display_none'
    },
    settings: {
        animationSpeedInMilliseconds: 200
    }
}

export default Style