import Box from '../../components/box/Box';
import GridGallery from '../../components/gallery/GridGallery';
import ResponsiveSection from '../../components/responsiveSection/ResponsiveSection';
import getLocales from '../../locale/Locale';

const imageList = [
    { url: '/images/gallery/moreska-1.jpg' },
    { url: '/images/gallery/moreska-2.jpg' },
    { url: '/images/gallery/moreska-3.jpg' },
    { url: '/images/gallery/moreska-4.jpg' },
    { url: '/images/gallery/moreska-5.jpg' },
    { url: '/images/gallery/moreska-6.jpg' },
]
enum Structure {
    container = 'gallery_container'
}
export default function GallerySection(){
    const { lang } = getLocales()

    return <>
        <ResponsiveSection>
            <Box class={Structure.container}>
                <GridGallery images={imageList} />
            </Box>
        </ResponsiveSection>
    </>
}