import React from 'react'
import { Helmet } from "react-helmet"

export const META_DESCRIPTION_TEXT_LIMIT = 155

export default function Seo(props: Hotelstouch.WebApp.Components.Seo.Import){

    let title = props.title ? `${props.title} | Moreška` : 'Moreška'
    let desciption = props.metaDescription ? props.metaDescription : ''
    
    return <Helmet>
        <title>{title}</title>
        { desciption && <meta name={'description'} content={desciption} />}
        { props.noIndex ? <meta name={'robots'} content={'noindex, follow'} /> : undefined}

        <meta property="og:title" content={title}/>
        <meta property='og:url' content={window.location.href.split('?')[0]} />
        { desciption && <meta property="og:description" content={desciption} />}
        { props.companyName && <meta property='og:site_name' content={props.companyName} />}
        { props.socialMediaImageUrl && <meta property="og:image" content={props.socialMediaImageUrl} />}

        <meta property="twitter:title" content={title}/>
        { desciption && <meta property="twitter:description" content={desciption} />}
        { props.socialMediaImageUrl && <meta property="twitter:image" content={props.socialMediaImageUrl} />}
    </Helmet>
}