import React from "react"
import './ResponsiveSection.scss'

enum Structure {
    container = 'container',
}
export default function ResponsiveSection(props: Hotelstouch.WebApp.Components.ResponsiveSection.Import){
    return <>
        <div className={`${Structure.container} ${props.class ? props.class : ''}`} style={props.style}>
            {props.children}
        </div>
    </>
}