import { Link } from 'react-router-dom';
import getLocales from '../../locale/Locale';
import { webShopPath } from '../../pages/webshop/WebShop';
import Style from '../../styles/Style';
import Button from '../button/Button';
import './HeroSection.scss';

enum Structure {
    heroSection = 'hero_section',
    heroTitle = 'hero_title',
    heroSubtitle = 'hero_subtitle'
}
export default function HeroSection(){
    const { lang } = getLocales()

    return <>
        <div className={Structure.heroSection}>
            <div className={Structure.heroTitle}>
                <img src={'/images/moreska_text_white.webp'} alt="Moreška" />
                <p className={Structure.heroSubtitle}>
                    {lang.heroSectionText}
                </p>
                <p>
                <Link to={webShopPath}>
                    <Button render={lang.buyTickect} primary={true} style={{ background: Style.variables.colorPrimary }} />
                </Link>
                </p>
            </div>
        </div>
    </>
}