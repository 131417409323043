import { useEffect, useState } from "react";

export enum ResponseStatus {
    SUCCESS = 0,
    ERROR = 1
}

export function useGetData<Req, Res>(props: Hotelstouch.WebApp.Hooks.GetData.Import<Req, Res>){
    const [response, setResponse] = useState<Res>(props.default || {} as Res)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const load = async () => {
        setIsLoading(true)
        const _response = await props.api(props.params)
        setIsLoading(false)
        setResponse(_response)
    }

    const clear = ()=> setResponse(props.default || {} as Res)

    useEffect(() => {
        if(!props.wait){
            load()
        }
    }, [])

    return {
        ...response,
        isLoading,
        clear,
        reload: load
    }
}

export function usePostData<Req, Res>(props: Hotelstouch.WebApp.Hooks.PostData.Import<Req, Res>){
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const execute = async (data: Req) => {
        setIsLoading(true)
        const _response = await props.api(data)
        setIsLoading(false)

        if(props.onResponse){
            props.onResponse(_response)
        }
    }

    return {
        isLoading,
        execute
    }
}