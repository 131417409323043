import React from "react";
import Divider from "../../components/divider/Divider";
import Icon from "../../components/icon/Icon";
import IconList from "../../components/icon/IconList";
import Page from "../../components/page/Page";
import ResponsiveSection from "../../components/responsiveSection/ResponsiveSection";
import Seo from "../../components/seo/Seo";
import getLocales from "../../locale/Locale";
import './AboutUs.scss'

enum Structure{
    sectionTitle = 'about_us_section_title',
    sectionSubtitle = 'about_us_section_subtitle',
    sectionText = 'about_us_section_text',
    containerPadded = 'container_padded',
    fileDownloadButton = 'about_us_file_download_button',
    fileDownloadIcon = 'about_us_file_download_button_icon'
}

const moreskaLegalFilePath = '/files/Statut_Moreška-Korčula_28.12.2020.pdf';

export const aboutUsPagePath = "/onama";
export default function AboutUs(){
    const { lang } = getLocales()

    return <>
        <Seo title={lang.aboutUs} />
        <Page 
            headerUrl={'/images/impressum.webp'}
            title={'Impressum'}
            subtitle={lang.ourLegalData}
        >
            <ResponsiveSection class={Structure.containerPadded}
                children={<>
                    <div className={Structure.sectionTitle}>{lang.aboutUs}</div>
                    <div className={Structure.sectionText}>{lang.contactParagraphText}</div>
                </>}
            />

            <ResponsiveSection class={Structure.containerPadded}
                children={<>
                    <div className={Structure.sectionTitle}>{lang.legalActs}</div>
                    <a className={Structure.fileDownloadButton} href={moreskaLegalFilePath} download="Statut_Moreška_Korčula.pdf">
                        <div className={Structure.fileDownloadIcon}><Icon icon={IconList.pdf} /></div>
                        <span>Statut_Moreška_Korčula.pdf</span>
                        <span style={{ color: '#00000040' }}><Icon icon={IconList.arrowDown} /></span>
                    </a>
                </>}
            />

            <ResponsiveSection class={Structure.containerPadded}
                children={<>
                    <div className={Structure.sectionTitle}>{lang.socialEntities}</div>
                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.president}:</div>
                        {'Gorana Depolo'}
                    </p>

                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.governingBoard}:</div>
                        {'Lovor Lozica, Mirna Vilović, Ivo Nazifovski, Ivan Šain, Jerko Šegedin, Saša Tedeschi'}
                    </p>

                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.headOfTheBrassMusicSection}:</div>
                        {'Alen Krajančić'}
                    </p>

                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.headOfTheChoirSection}:</div>
                        {'Goran Milošević'}
                    </p>

                    <p>
                        <div className={Structure.sectionSubtitle}>{lang.headOfTheFolkloreSection}:</div>
                        {'Janja Lozica'}
                    </p>
                </>}
            />

            <Divider />
        </Page>
    </>
}