import { useState } from "react";
import { useParams } from "react-router";
import Box, { boxStyles } from "../../components/box/Box";
import Button from "../../components/button/Button";
import Page from "../../components/page/Page";
import Print from "../../components/print/Print";
import ResponsiveSection from "../../components/responsiveSection/ResponsiveSection";
import Seo from "../../components/seo/Seo";
import { useGetData } from "../../hooks/api/Api";
import getLocales from "../../locale/Locale";
import { getCartInfo } from "../../webShop/api/CartInfo";
import { printBillUrl, printTicketUrl } from "../../webShop/api/Print";
import { CartInfoProvider } from "../../webShop/context/CartInfo";
import './WebShop.scss';

enum Structure {
    accentBox = 'accent_box',
    layout = 'webshop_layout'
}

export const orderInfoPagePathNoParam = "/status-narudzbe"
export const orderInfoPagePath = "/status-narudzbe/:orderGuid"
export default function OrderInfoPage(){
    const { lang } = getLocales()

    const { orderGuid } = useParams<'orderGuid'>()
    const info = useGetData({
        api: getCartInfo,
        params: { orderGuid: orderGuid }
    })

    return <div className={Structure.layout}>
        <Seo title={lang.orderSummary} />
        <CartInfoProvider>
            <Page
                headerUrl={'/images/footer_background.jpg'}
                title={info.isLoading ? lang.loading: (info.object ? lang.paymentConfirmation : lang.orderCanceled)}
                subtitle={info.isLoading ? lang.loading: (info.object ? lang.yourPaymentWasSuccessful : lang.yourOrderHasBeenCanceled)}>
                    <ResponsiveSection class={Structure.accentBox} style={{ backgroundColor: 'transparent', paddingTop: 0, paddingBottom: 0 }}>
                        { info.isLoading ? 
                            lang.loading :
                            <>{!info.object ? <>{lang.yourOrderHasBeenCanceled}</> : 
                                <>
                                    <h2 style={{ textDecoration:'none' }}>{lang.successfulPurchase}!</h2>
                                    <p>
                                        {lang.dearCustomer}
                                    </p>
                                    <p>
                                        {lang.thankYouForPurchasingYourTicket}
                                    </p>
                                    <p>
                                        {lang.yourTicketWillBeSendInfoText}
                                    </p>
                                    <p>
                                        {lang.seeYouSoon}
                                    </p>
                                </>
                            }
                            <br /><br />
                                <Box class={boxStyles.flexBox} style={{ justifyContent: 'space-between' }}>
                                    <Button
                                        link="/"
                                        primary={true}
                                        render={lang.homePage}
                                    />
                                    { orderGuid && info.object &&
                                        <Box class={boxStyles.flexBox} style={{ gap: '1rem' }}>
                                            <Button
                                                link={printBillUrl(orderGuid)}
                                                targetBlank={true}
                                                primary={true}
                                                render={lang.printBill}
                                            />
                                            <Button
                                                link={printTicketUrl(orderGuid)}
                                                targetBlank={true}
                                                primary={true}
                                                render={lang.printTicket}
                                            />
                                        </Box>
                                    }
                                </Box>
                            </>
                        }
                        
                    </ResponsiveSection>
            </Page>
        </CartInfoProvider>
    </div>
}