import { applicationCode } from "./Settings";

export const fetchData = async <T>(url: string, params?: any, options?: RequestInit): Promise<T> => {
    //console.log('%c Fetching data...', 'background: #4e2675; color: white', url, params);
    let _url = `${url}${url.indexOf('?') > 0 ? '&' : '?'}applicationCode=${applicationCode}`
    let response = await fetch(_url, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(params || {}),
        ...options,
    });
    
    let responseJson = response && response.headers && response.headers.get('Content-Type') && response.headers.get('Content-Type')!.indexOf('application/json') >= 0 ? await response.json() : await response.text();
    return responseJson;
};

export const getFullUrlFromRelativeUrl = (url: string) => {
    return new URL(url, document.baseURI).href
}