import React from "react";
import './InfoParagraph.scss';

enum Structure {
    paragraph = 'info_paragraph',
    paragraphTitle = 'info_paragraph_title',
    paragraphContent = 'info_paragraph_content',
    paragraphAdditionalContent = 'info_paragraph_additional_content'
}
export default function InfoParagraph(props: Hotelstouch.WebApp.Components.InfoParagraph.Import){
    return <>
        <div className={Structure.paragraph}>
            {props.title && <h2 className={Structure.paragraphTitle}>{props.title}</h2>}
            <p className={Structure.paragraphContent}>{props.content}</p>
            {props.additionalContent && <div className={Structure.paragraphAdditionalContent}>{props.additionalContent}</div>}
        </div>
    </>
}