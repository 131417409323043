import './Box.scss'
import React from "react";

enum Structure {
    box = 'box'
}

export default function Box(props: Hotelstouch.WebApp.Components.Box.Import){
    return <div className={`${Structure.box} ${props.class}`} style={props.style}>
        {props.children}
    </div>
}
export const boxStyles = {
    flexBox: 'flex',
    flexEnd: 'flex_end',
    flexGrid: (columns: 2|3|4) => {
        return 'flex_grid_' + columns
    }
}