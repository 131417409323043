import getLocales from "../locale/Locale";
import { aboutUsPagePath } from "../pages/aboutUs/AboutUs";
import { contactUsPagePath } from "../pages/contactUs/ContactUs";
import { landingPagePath } from "../pages/landingPage/LandingPage";
import { webShopPath } from "../pages/webshop/WebShop";

export default function getNavigationList(){
    const { lang } = getLocales()

    return [
        {
            title: lang.homePage,
            url: landingPagePath
        },
        {
            title: lang.aboutUs,
            url: aboutUsPagePath
        },
        {
            title: lang.buyTickect,
            url: webShopPath
        },
        {
            title: lang.contact,
            url: contactUsPagePath
        }
    ] as Hotelstouch.WebApp.Components.NavBar.Route[]
}