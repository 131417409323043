import React from "react";
import Button from "../../../components/button/Button";
import { addLog } from "../../../components/feedback/Feedback";
import useForm from "../../../components/form/Form";
import InfoParagraph from "../../../components/infoParagraph/InfoParagraph";
import CheckInput from "../../../components/input/CheckInput";
import TextInput from "../../../components/input/TextInput";
import ResponsiveSection from "../../../components/responsiveSection/ResponsiveSection";
import { ResponseStatus, usePostData } from "../../../hooks/api/Api";
import getLocales from "../../../locale/Locale";
import { isEmail, isNullOrEmpty } from "../../../utils/TypeUtil";
import { saveUserMessage } from "../../../webShop/api/SendUserMessage";
import './ContactUsForm.scss'

enum Structure{
    contactFormWrapper = 'contact_form_wrapper',
    center = 'center'
}

export default function ContactUsForm(){
    const { lang } = getLocales()

    const save = usePostData({
        api:saveUserMessage,
        onResponse:(response) => {
            if(response){
                addLog(response)
            }
            if(response?.status == ResponseStatus.SUCCESS){
                form.reload()
            }
        }
    })

    const form = useForm({
        data: {} as Hotelstouch.WebApp.WebShop.Api.SendUserMessage.Request,
        validator: (data) => {
            let error = {} as Hotelstouch.WebApp.Components.Form.Errors<Hotelstouch.WebApp.WebShop.Api.SendUserMessage.Request>

            if(isNullOrEmpty(data.contactEmail)) error.contactEmail = lang.fieldIsRequired
            else if(!isEmail(data.contactEmail)) error.contactEmail = lang.valueNeedsToBeAValidEmail

            if(isNullOrEmpty(data.name)) error.name = lang.fieldIsRequired
            if(isNullOrEmpty(data.message)) error.message = lang.fieldIsRequired
            if(!data.agreeGDPR) error.agreeGDPR = lang.fieldIsRequired

            return error
        },
        onSubmit: (data) => save.execute(data)
    })

    return <>
        <ResponsiveSection>
            <InfoParagraph 
                title={lang.contactForm}
                content={<>
                    <form style={{ width: '100%' }} onSubmit={form.submit} autoComplete={'on'}>

                        <div className={`${Structure.contactFormWrapper} ${Structure.center}`}>
                            <TextInput 
                                name="name" 
                                label={lang.firstAndLastName}
                                autocomplete={true}
                                error={form.formVisited ? form.errors.name : undefined}
                                value={form.data.name}
                                onChange={form.setValue} 
                            />

                            <TextInput
                                name="contactEmail" 
                                label={lang.email}
                                autocomplete={true}
                                error={form.formVisited ? form.errors.contactEmail : undefined}
                                value={form.data.contactEmail}
                                onChange={form.setValue} 
                            />

                            <TextInput 
                                name="message" 
                                label={lang.message}
                                autocomplete={true}
                                error={form.formVisited ? form.errors.message : undefined}
                                value={form.data.message}
                                onChange={form.setValue} 
                            />

                            <CheckInput
                                name="agreeGDPR"
                                checked={form.data.agreeGDPR}
                                label={<span style={{ fontSize: '0.8rem', textAlign: 'left' }}>
                                    {lang.agreeGDPR}
                                </span>}
                                onChange={form.setValue} 
                            />

                            <Button
                                render={lang.sendInquiry}
                                disabled={!form.isValid()}
                                primary={true}
                                type='submit'
                                style={{ margin: '1rem auto' }}
                                isLoading={false}
                            />
                        </div>

                    </form>
                </>}
            />            
        </ResponsiveSection>
    </>
}