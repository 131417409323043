export function formatCurrency(x: number){

    if(!x) x = 0;

    try{
        return x.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace(/\./g,',').replace(' ','.');
    }
    catch(e){
        return x;
    }
}

export function isNullOrEmpty(str?: string){
    if(!str || str === ''){
        return true
    }
    else{
        return false
    }
}
export function isEmail(email?: string): boolean {
    if (!email) return false;
    return /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/g.test(
        email
    );
}