import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { createPortal } from 'react-dom'
import Button from "../../../components/button/Button";
import Countdown from "../../../components/countdown/Countdown";
import DataMap from "../../../components/data/DataMap";
import ResponsiveSection from "../../../components/responsiveSection/ResponsiveSection";
import getLocales from "../../../locale/Locale";
import { formatCurrency } from "../../../utils/TypeUtil";
import useCartInfo from "../../../webShop/context/CartInfo";
import './OrderSummary.scss';
import moment from "moment";
import Divider from "../../../components/divider/Divider";
import Icon from "../../../components/icon/Icon";
import IconList from "../../../components/icon/IconList";

enum Structure {
    totalRow = 'total_row',
    displayCurrency = 'display_currency',
    accentBox = 'accent_box',
    tableBox = 'table_box',
    orderSummaryHeader = 'order_summary_header'
}

export const CART_BUTTON_UI_ID = "cartButton"
export const CART_SECTION_UI_ID = "cartSection"

export default function OrderSummary(){

    const { lang } = getLocales()
    const { cart, removeOrderItem, reload } = useCartInfo()

    const cartSectionRef = useRef<HTMLDivElement>(null)
    const portalOutlet = document.getElementById(CART_BUTTON_UI_ID)

    return <>
        {cart && cart.items && cart.items.length > 0 && <>
            <Divider />
            <div ref={cartSectionRef} style={{ scrollMargin: 'var(--navBarHeight)' }}>
                <ResponsiveSection class={Structure.accentBox}>

                    {portalOutlet && createPortal(
                        <Button
                            class="slide_in_from_top"
                            media={<Icon icon={IconList.cart} />}
                            notification={cart.items ? <span>{cart.items.length}</span> : undefined}
                            onClick={() => {
                                if(cartSectionRef && cartSectionRef.current){
                                    cartSectionRef.current.scrollIntoView({ behavior: "smooth" })
                                }
                            }}
                        />
                    , portalOutlet)}

                    <div className={Structure.orderSummaryHeader}>
                        <h2>{lang.orderSummary}</h2>
                        <Countdown onTimeout={reload} expirationDateTime={moment(cart.expirationDateTime, 'DD/MM/YYYY HH:mm:ss')} />
                    </div>
                    <br />
                    <div className={Structure.tableBox}>
                        <table>
                            <thead>
                                <tr>
                                    <th>{lang.selectedTicket}</th>
                                    <th>{lang.numberOfTickets}</th>
                                    <th>{lang.total}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            <DataMap data={cart.items} render={({ data: item }) => (
                                <tr key={item.id}>
                                    <td>{item.title}</td>
                                    <td>{item.quantity}</td>
                                    <td>{formatCurrency(item.price)} {cart.currency}</td>
                                    <td>
                                        <Button
                                            style={{ marginLeft: 'auto' }}
                                            media={<FontAwesomeIcon icon={faTrash} />}
                                            onClick={() => removeOrderItem(item.id, item.pibpId)}
                                        />
                                    </td>
                                </tr>
                            )}/>
                            </tbody>
                        </table>
                    </div>
                    <div className={Structure.totalRow}>
                        <div>{lang.total}: {formatCurrency(cart.totalPrice)} {cart.currency}<br/></div>
                    </div>
                    <br />
                    <i style={{ opacity: 0.8 }}><Icon icon={IconList.arrowDown} /> {lang.scrollDownForPayment}</i>
                </ResponsiveSection>
            </div>
            </>
        }
    </>
}