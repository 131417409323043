import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import './Input.scss';

enum Structure {
    area = 'check_area',
    media = 'input_media'
}
export default function CheckInput(props: Hotelstouch.WebApp.Components.Input.CheckBox.Import){

    return <div className={Structure.area} onClick={() => { props.onChange({ key: props.name, value: !props.checked }) }}>
        <FontAwesomeIcon icon={props.checked ? faSquareCheck : faSquare} />
        {props.label}
    </div>
}